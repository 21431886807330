import React from 'react';
import Icon from '@ant-design/icons';

const RfidSvg = () => (
	<svg
		width='18'
		height='18'
		viewBox='0 0 18 18'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M9 18C13.9706 18 18 13.9706 18 9C18 4.02943 13.9706 0 9 0C4.02943 0 0 4.02943 0 9C0 13.9706 4.02943 18 9 18Z'
			fill='#FFBF1F'
		/>
		<path
			d='M16 7.52065H15.084C15.084 6.91595 14.9649 6.31714 14.7335 5.75847C14.5021 5.1998 14.163 4.69222 13.7354 4.26461C13.3078 3.83704 12.8002 3.49787 12.2416 3.26649C11.683 3.03513 11.0842 2.91602 10.4796 2.91605V2C11.2045 1.9999 11.9224 2.14264 12.5922 2.42005C13.262 2.69746 13.8706 3.10407 14.3832 3.61671C14.8958 4.1294 15.3024 4.73804 15.5799 5.40786C15.8573 6.07772 16 6.79564 16 7.52065ZM13.2718 7.52238H14.1881C14.1869 6.53859 13.7956 5.5954 13.1 4.89978C12.4044 4.20412 11.4612 3.81283 10.4776 3.81174V4.72817C11.2183 4.72899 11.9285 5.02363 12.4524 5.54747C12.9762 6.07128 13.2709 6.78155 13.2718 7.52238ZM13.4287 14H4V4.57021H8.71368V5.48667H4.91734V13.0829H12.5124V9.28477H13.4284L13.4287 14ZM11.6733 7.52037C11.6728 7.20385 11.5468 6.90043 11.323 6.67666C11.0991 6.45287 10.7958 6.32694 10.4793 6.3265V5.41008C11.0388 5.41079 11.5751 5.6334 11.9707 6.02904C12.3664 6.42471 12.5889 6.96112 12.5897 7.52068L11.6733 7.52037ZM6.12912 11.8709H11.301V8.88851H10.385V10.9549H7.04544V7.61498H8.31985V9.46516H9.23583V6.69886H6.12912V11.8709Z'
			fill='#00344E'
		/>
	</svg>
);

export const RfidIcon = (props) => (
	<Icon component={RfidSvg} className={'anticon-rfid'} {...props} />
);
