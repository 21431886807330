/**
 *
 * @param paths Obeject<path,title, component,roles<array>>
 * @returns {({path: string, hierarchical: boolean, unique: string, name: string, title: string}|{path: string, hierarchical: boolean, unique: string, name: string, title: string}|{path: string, hierarchical: boolean, unique: string, name: string, title: string}|{path: string, hierarchical: boolean, unique: string, name: string, title: string}|{path: string, hierarchical: boolean, unique: string, name: string, title: string})[]}
 */
export const catalogConfigs = (paths) => [
	{
		name: 'departments',
		path: `${paths.CATALOG.path}/departments`,
		title: 'Организационная структура предприятия',
		hierarchical: true,
		unique: 'департамента',
		creation: 'DEPARTMENTS_CREATION_SUCCESS',
		edition: 'DEPARTMENTS_EDITION_SUCCESS',
	},
	{
		name: 'techMapsStatuses',
		path: `${paths.CATALOG.path}/techMapsStatuses`,
		title: 'Статусы технологических карт',
		hierarchical: false,
		unique: 'статуса технологических карт',
		creation: 'TECH_MAPS_STATUSES_CREATION_SUCCESS',
		edition: 'TECH_MAPS_STATUSES_EDITION_SUCCESS',
	},
	{
		name: 'staffPositions',
		path: `${paths.CATALOG.path}/staffPositions`,
		title: 'Должности',
		hierarchical: false,
		unique: 'должности',
		creation: 'STAFF_POSITIONS_CREATION_SUCCESS',
		edition: 'STAFF_POSITIONS_EDITION_SUCCESS',
	},
	{
		name: 'equipments',
		path: `${paths.CATALOG.path}/equipments`,
		title: 'Оборудование',
		hierarchical: true,
		unique: 'оборудования',
		creation: 'EQUIPMENTS_CREATION_SUCCESS',
		edition: 'EQUIPMENTS_EDITION_SUCCESS',
		creationGroup: 'EQUIPMENTS_GROUP_CREATION_SUCCESS',
		editionGroup: 'EQUIPMENTS_GROUP_EDITION_SUCCESS',
	},
	{
		name: 'staffQualifications',
		path: `${paths.CATALOG.path}/staffQualifications`,
		title: 'Квалификации',
		hierarchical: false,
		unique: 'квалификации',
		creation: 'STAFF_QUALIFICATIONS_CREATION_SUCCESS',
		edition: 'STAFF_QUALIFICATIONS_EDITION_SUCCESS',
	},
	{
		name: 'defectStatusesProcess',
		path: `${paths.CATALOG.path}/defectStatusesProcess`,
		title: 'Статусы обработки дефектов',
		hierarchical: false,
		unique: 'статуса обработки дефекта',
		creation: 'DEFECT_STATUSES_PROCESS_CREATION_SUCCESS',
		edition: 'DEFECT_STATUSES_PROCESS_EDITION_SUCCESS',
	},
	{
		name: 'panelProblemsPriorities',
		path: `${paths.CATALOG.path}/panelProblemsPriorities`,
		title: 'Приоритеты панели проблем',
		hierarchical: false,
		unique: 'приоритета панели проблем',
		creation: 'PANEL_PROBLEMS_PRIORITIES_CREATION_SUCCESS',
		edition: 'PANEL_PROBLEMS_PRIORITIES_EDITION_SUCCESS',
	},
	{
		name: 'panelProblemsStatuses',
		path: `${paths.CATALOG.path}/panelProblemsStatuses`,
		title: 'Статусы панели проблем',
		hierarchical: false,
		unique: 'статуса панели проблем',
		creation: 'PANEL_PROBLEMS_STATUSES_CREATION_SUCCESS',
		edition: 'PANEL_PROBLEMS_STATUSES_EDITION_SUCCESS',
	},
	{
		name: 'sapStatuses',
		path: `${paths.CATALOG.path}/sapStatuses`,
		title: 'Статусы SAP',
		hierarchical: false,
		unique: 'статуса SAP',
		creation: 'SAP_STATUSES_CREATION_SUCCESS',
		edition: 'SAP_STATUSES_EDITION_SUCCESS',
	},
	{
		name: 'routeStatuses',
		path: `${paths.CATALOG.path}/routeStatuses`,
		title: 'Статусы обработки маршрутов',
		hierarchical: false,
		unique: 'статуса маршрута',
		creation: 'ROUTE_STATUSES_CREATION_SUCCESS',
		edition: 'ROUTE_STATUSES_EDITION_SUCCESS',
	},
	{
		name: 'repeaterStatuses',
		path: `${paths.CATALOG.path}/repeaterStatuses`,
		title: 'Статусы обработки расписания обходов',
		hierarchical: false,
		unique: 'статуса расписания обхода',
		creation: 'SCHEDULES_STATUSES_CREATION_SUCCESS',
		edition: 'SCHEDULES_STATUSES_EDITION_SUCCESS',
	},
	{
		name: 'staffWorkSchedules',
		path: `${paths.CATALOG.path}/staffWorkSchedules`,
		title: 'Рабочие графики',
		hierarchical: false,
		unique: 'рабочего графика',
		creation: 'STAFF_WORK_SCHEDULES_CREATION_SUCCESS',
		edition: 'STAFF_WORK_SCHEDULES_EDITION_SUCCESS',
	},
	{
		name: 'detoursStatuses',
		path: `${paths.CATALOG.path}/detoursStatuses`,
		title: 'Статусы обработки обходов',
		hierarchical: false,
		unique: 'статуса обхода',
		creation: 'DETOURS_STATUSES_CREATION_SUCCESS',
		edition: 'DETOURS_STATUSES_EDITION_SUCCESS',
	},
	{
		name: 'defectTypical',
		path: `${paths.CATALOG.path}/defectTypical`,
		title: 'Типовые дефекты',
		hierarchical: true,
		unique: 'типового дефекта',
		creation: 'DEFECT_TYPICAL_CREATION_SUCCESS',
		edition: 'DEFECT_TYPICAL_EDITION_SUCCESS',
		creationGroup: 'DEFECT_TYPICAL_GROUP_CREATION_SUCCESS',
		editionGroup: 'DEFECT_TYPICAL_GROUP_EDITION_SUCCESS',
	},
	{
		name: 'staff',
		path: `${paths.CATALOG.path}/staff`,
		title: 'Сотрудник',
		hierarchical: false,
		unique: 'карточки сотрудника',
		creation: 'STAFF_CREATION_SUCCESS',
		edition: 'STAFF_EDITION_SUCCESS',
	},
];
